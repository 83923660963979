<template>
  <web-area>
    <h1>Téléchargements</h1>
    <div v-if="!(this.stableVersion() == null && this.betaVersion() == null)">
      <p>En tant que juge, pour une meilleure expérience, notamment lorsque vous êtes privé de connexion internet, nous vous recommandons de télécharger l'application pour Android.</p>
      <p><i>Si vous n'êtes pas juges, dt, ... cette application, dans sa version actuelle, ne vous sera pas d'une grande utilité, mais vous pouvez néanmoins la télécharger pour suivre les compétitions en cours d'exécution...</i></p>
      <p>Pour ce faire, cliquez sur l'un des liens ci-dessous :</p>
      <ul>
        <li><a :href="appDownloadLink" v-if="stableVersion() != null">Version {{ stableVersion().versionNumber }} du {{ stableVersion().date }} : Dernière version stable de l'application (recommandé).</a></li>
        <li><a :href="appBetaDownloadLink" v-if="betaVersion() != null">Version {{ betaVersion().versionNumber }} du {{ betaVersion().date }} : Dernière version bêta.</a></li>
      </ul>
    </div>
    <div v-else>
      <p>Fonctionnalité en cours de développement...</p>
    </div>
  </web-area>
</template>

<script>
import WebArea from "@/components/WebArea";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Downloads",
  components: { WebArea },
  computed:{
    ...mapGetters("config", ["serverBaseUrl"]),
    ...mapGetters("synchro", ["getLastAppStableVersionInfo", "getLastAppBetaVersionInfo"]),
    appDownloadLink(){
      var url = this.serverBaseUrl + '/downloads/ffvlcvscoring.apk';
      return url;
    },
    appBetaDownloadLink(){
      var url = this.serverBaseUrl + '/downloads/ffvlcvscoring-beta.apk';
      return url;
    },

  },
  methods:{
    ...mapActions("synchro", ["getDownloadableAppVersions"]),
    stableVersion(){ return this.getLastAppStableVersionInfo; },
    betaVersion(){ return this.getLastAppBetaVersionInfo; },
  },
  mounted(){
    this.getDownloadableAppVersions();
    this.$hideLoader();
  }
}
</script>
